<template>
  <form >
    <v-text-field
        label="Nickname"
        disabled
        :value="$store.state.user.name"
    />
    <v-text-field
        label="E-mail"
        disabled
        :value="$store.state.user.email"
    />
    <v-text-field
        label="User ID"
        disabled
        :value="$store.state.user.id"
    />
    <v-text-field
        label="Steam ID"
        disabled
        :value="$store.state.user.steam"
    />
  </form>
</template>

<script>

export default {

  data: () => ({
    name: '',
    email: '',
    select: null,
    items: [
      'Item 1',
      'Item 2',
      'Item 3',
      'Item 4',
    ],
    checkbox: false,
  }),


}
</script>

<style scoped>

</style>

<i18n>
{
"ru":{
"Must be valid e-mail":"Введите правильную почту",
"E-mail is required":"Почта обязательна"
}
}
</i18n>