<template>
  <v-row no-gutters justify="center">
    <winblock>
      <template #win-title>Пользователь</template>
      <block1></block1>
    </winblock>
  </v-row>
</template>

<script>
import winblock from "@/components/winblock";
import Block1 from "../site/modules/core/user-profile";


export default {
  name: "index",
  components: {Block1, winblock}
}
</script>

<style scoped>

</style>